<template>
	<modal class="NoxModalPerks" name="NoxModalPerks" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title" v-html="$store.getters.getLanguageText('5.12', 0)"></div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="noxBuyStep == 1">
						<p v-html="$store.getters.getLanguageText('5.12', 1, { count: $store.state.noxAccountData.count_of_perks })"></p>
						<p v-html="$store.getters.getLanguageText('5.12', 2, { name: noxPackageName })"></p>
						<p class="nox_p_radios">
							<label class="nox_radio" :class="{ disabled: !noxIsActivePerkBonus1 }" for="nox_radio_perk_1">
								<input type="radio" id="nox_radio_perk_1" name="nox_radio_perk" value="1" v-model.number="noxPerkBonus" :disabled="!noxIsActivePerkBonus1">
								<span class="nox_radio_mark"></span>
								<span class="nox_radio_text"><span v-html="$store.getters.getLanguageText('5.12', 3, { value: $store.state.noxSystemSettings.perk_bonus_1 })"></span><span v-if="!noxIsActivePerkBonus1">&#160;<span v-html="$store.getters.getLanguageText('5.12', 11)"></span></span></span>
							</label>
							<label class="nox_radio" :class="{ disabled: !noxIsActivePerkBonus2 }" for="nox_radio_perk_2">
								<input type="radio" id="nox_radio_perk_2" name="nox_radio_perk" value="2" v-model.number="noxPerkBonus" :disabled="!noxIsActivePerkBonus2">
								<span class="nox_radio_mark"></span>
								<span class="nox_radio_text"><span v-html="$store.getters.getLanguageText('5.12', 4, { value: $store.state.noxSystemSettings.perk_bonus_2 })"></span><span v-if="!noxIsActivePerkBonus2">&#160;<span v-html="$store.getters.getLanguageText('5.12', 11)"></span></span></span>
							</label>
							<label class="nox_radio" :class="{ disabled: !noxIsActivePerkBonus3 }" for="nox_radio_perk_3">
								<input type="radio" id="nox_radio_perk_3" name="nox_radio_perk" value="3" v-model.number="noxPerkBonus" :disabled="!noxIsActivePerkBonus3">
								<span class="nox_radio_mark"></span>
								<span class="nox_radio_text"><span v-html="$store.getters.getLanguageText('5.12', 5, { value: $store.state.noxSystemSettings.perk_bonus_3 })"></span><span v-if="!noxIsActivePerkBonus3">&#160;<span v-html="$store.getters.getLanguageText('5.12', 11)"></span></span></span>
							</label>
							<label class="nox_radio" :class="{ disabled: !noxIsActivePerkBonus4 }" for="nox_radio_perk_4">
								<input type="radio" id="nox_radio_perk_4" name="nox_radio_perk" value="4" v-model.number="noxPerkBonus" :disabled="!noxIsActivePerkBonus4">
								<span class="nox_radio_mark"></span>
								<span class="nox_radio_text"><span v-html="$store.getters.getLanguageText('5.12', 6)"></span><span v-if="!noxIsActivePerkBonus4">&#160;<span v-html="$store.getters.getLanguageText('5.12', 11)"></span></span></span>
							</label>
						</p>
					</div>
					<div v-else-if="noxBuyStep == 2">
						<div v-if="noxPerkBonus == 1" v-html="$store.getters.getLanguageText('5.12', 7, { bonus: $store.getters.getLanguageText('5.12', 3, { value: $store.state.noxSystemSettings.perk_bonus_1 }) })"></div>
						<div v-else-if="[2,4].includes(noxPerkBonus)">
							<div v-if="noxPerkBots.length">
								<p v-html="$store.getters.getLanguageText('5.12', 9)"></p>
								<p class="nox_p_radios">
									<label class="nox_radio" :class="{ disabled: (!value.status || (noxPerkBonus == 2 && value.is_perk_deposit_limit > 0) || (noxPerkBonus == 4 && value.is_perk_insomnia > 0)) }" :for="'nox_radio_perk_bot_' + index" v-for="(value, index) in noxPerkBots" :key="index">
										<input type="radio" :id="'nox_radio_perk_bot_' + index" name="nox_radio_perk_bot" :value="value.id" v-model.number="noxPerkBotId" :disabled="(!value.status || (noxPerkBonus == 2 && value.is_perk_deposit_limit > 0) || (noxPerkBonus == 4 && value.is_perk_insomnia > 0))">
										<span class="nox_radio_mark"></span>
										<span class="nox_radio_text">
											<span v-html="$store.getters.getLanguageText('5.12', 10, { id: value.id })"></span>
											<span v-if="!value.status">&#160;<span v-html="$store.getters.getLanguageText('5.12', 12)"></span></span>
											<span v-else-if="((noxPerkBonus == 2 && value.is_perk_deposit_limit > 0) || (noxPerkBonus == 4 && value.is_perk_insomnia > 0))">&#160;<span v-html="$store.getters.getLanguageText('5.12', 13)"></span></span>
										</span>
									</label>
								</p>
							</div>
							<div v-else v-html="$store.getters.getLanguageText('5.12', 8)"></div>
						</div>
						<div v-else-if="noxPerkBonus == 3" v-html="$store.getters.getLanguageText('5.12', 7, { bonus: $store.getters.getLanguageText('5.12', 5, { value: $store.state.noxSystemSettings.perk_bonus_3 }) })"></div>
					</div>
					<div v-html="noxAlertPerk"></div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="back" v-if="noxBuyStep == 2" v-html="$store.getters.getLanguageText('1.1', 55)"></button>
					<button type="button" class="nox_button common" @click="close" v-else v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="axios('usePerkBonus')" v-if="noxBuyStep == 1" v-html="$store.getters.getLanguageText('1.1', 68)"></button>
					<button type="button" class="nox_button common green" @click="axios('usePerkBonus')" v-else-if="noxBuyStep == 2 && ([1,3].includes(noxPerkBonus) || ([2,4].includes(noxPerkBonus) && noxPerkBots.length))" v-html="$store.getters.getLanguageText('1.1', 67)"></button>
					<button type="button" class="nox_button common green" @click="addBot" v-else-if="noxBuyStep == 2 && [2,4].includes(noxPerkBonus) && !noxPerkBots.length" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertPerk: '',
			noxBuyStep: 0,
			noxPackage: 0,
			noxPackageName: '',
			noxPerkBonus: 0,
			noxPerkBots: [],
			noxPerkBotId: 0,
			noxIsActivePerkBonus1: 0,
			noxIsActivePerkBonus2: 0,
			noxIsActivePerkBonus3: 0,
			noxIsActivePerkBonus4: 0,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function() {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxBuyStep = 1;
				this.noxPackage = this.$store.state.noxAccountData.package;
				this.noxPackageName = this.$store.state.noxSystemSettings['package_name_x' + this.noxPackage];
				this.noxPerkBonus = 0;
				this.noxPerkBots = [];
				this.noxPerkBotId = 0;
				this.noxIsActivePerkBonus1 = Number(this.$store.state.noxSystemSettings['is_active_perk_bonus_1_for_x' + this.noxPackage]);
				this.noxIsActivePerkBonus2 = Number(this.$store.state.noxSystemSettings['is_active_perk_bonus_2_for_x' + this.noxPackage]);
				this.noxIsActivePerkBonus3 = Number(this.$store.state.noxSystemSettings['is_active_perk_bonus_3_for_x' + this.noxPackage]);
				this.noxIsActivePerkBonus4 = Number(this.$store.state.noxSystemSettings['is_active_perk_bonus_4_for_x' + this.noxPackage]);
				this.noxIsLoading = false;
				this.noxIsError = false;
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertPerk = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.14', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.14', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.14', 2); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.14', 3); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.14', 4); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.14', 5); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3.14', 6); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3.14', 7); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3.14', 8); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'usePerkBonus') {
						if (_this.noxBuyStep == 1) {
							if (!_this.noxPerkBonus) {
								_this.noxAlertPerk = _this.getError(1);
							}
							else if (!_this.$store.state.noxRegex_d.test(_this.noxPerkBonus) || ![1,2,3,4].includes(_this.noxPerkBonus)) {
								_this.noxAlertPerk = _this.getError(2);
							}
							else if ((_this.noxPerkBonus == 1 && !_this.noxIsActivePerkBonus1) || (_this.noxPerkBonus == 2 && !_this.noxIsActivePerkBonus2) || (_this.noxPerkBonus == 3 && !_this.noxIsActivePerkBonus3) || (_this.noxPerkBonus == 4 && !_this.noxIsActivePerkBonus4)) {
								_this.noxAlertPerk = _this.getError(8);
							}
							else if (!_this.$store.state.noxAccountData.count_of_perks) {
								_this.noxAlertPerk = _this.getError(9);
							}
							if (!_this.noxIsError) {
								if ([2,3,4].includes(_this.noxPerkBonus)) {
									_this.noxPerkBotId = 0; _this.axios('getPerkBots'); return false;
								}
								_this.noxBuyStep = 2; return false;
							}
						}
						else if (_this.noxBuyStep == 2) {
							if ([2,4].includes(_this.noxPerkBonus)) {
								if (!_this.noxPerkBotId) {
									_this.noxAlertPerk = _this.getError(3);
								}
								else if (!_this.$store.state.noxRegex_d.test(_this.noxPerkBotId)) {
									_this.noxAlertPerk = _this.getError(4);
								}
							}
							if (!_this.noxIsError) {
								config.url = '/v2/account/balance/perks/use';
								config.data = { bonus: _this.noxPerkBonus, bot_id: _this.noxPerkBotId };
								config.method = 'post';
							}
						}
					}
					else if (type == 'getPerkBots') {
						config.url = '/v2/account/balance/perks/bots';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'usePerkBonus') {
									if (data.data.account_data !== undefined) {
										_this.$parent.$parent.initAccountData(data.data.account_data);
									}
									if (data.data.bots_data !== undefined) {
										if (['/account/bots', '/account/bots/forex', '/account/bots/crypto', '/account/bot_settings'].includes(_this.$route.path)) {
											if ([2,3,4].includes(_this.noxPerkBonus)) {
												_this.$parent.$parent.initBotsData(data.data.bots_data);
											}
										}
									}
									_this.close();
								}
								else if (type == 'getPerkBots') {
									_this.noxBuyStep = 2;
									_this.noxPerkBots = data.data;
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if      (data.response.data.error == 'BONUS_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
									else if (data.response.data.error == 'BONUS_NOT_VALID') { _this.noxAlert = _this.getError(2); }
									else if (data.response.data.error == 'BOT_ID_IS_EMPTY') { _this.noxAlert = _this.getError(3); }
									else if (data.response.data.error == 'BOT_ID_NOT_VALID') { _this.noxAlert = _this.getError(4); }
								}
								else if (data.response.status == 401) { _this.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (data.response.data.error == 'BOT_ID_NOT_FOUND') { _this.noxAlert = _this.getError(5); }
								}
								else if (data.response.status == 409) {
									if      (data.response.data.error == 'BOT_IS_NOT_RUNNING') { _this.noxAlert = _this.getError(6); }
									else if (data.response.data.error == 'ALREADY_ACTIVATED') { _this.noxAlert = _this.getError(7); }
									else if (data.response.data.error == 'FORBIDDEN_BONUS') { _this.noxAlert = _this.getError(8); }
									else if (data.response.data.error == 'NOT_ENOUGH_PERKS') { _this.noxAlert = _this.getError(9); }
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.exit();
				}
			},
			addBot: function() {
				if (!['/account/bots', '/account/bots/forex', '/account/bots/crypto', '/account/bot_settings'].includes(this.$route.path)) {
					this.$router.push({ path: '/account/bots' });
				}
				this.close();
			},
			back: function() {
				this.noxAlert = '';
				this.noxAlertPerk = '';
				this.noxBuyStep = 1;
			},
			close: function() {
				this.$modal.hide('NoxModalPerks');
			}
		}
	}
</script>
